<template>
  <administration-layout>
    <div class="flex flex-col items-center py-4" v-loading.fullscreen.lock="loading">
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold inline-flex items-center">
            <strong>&nbsp;Notificaciones</strong>
          </h1>
          <div class="ml-auto">
            <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="save">
              Guardar
            </sa-button>
          </div>
        </div>
        <div class="flex flex-col space-y-6">
          <content-card>
            <table class="text-left border border-collapse border-slate-500 border-separate border-spacing-2">
              <thead>
                <tr>
                  <th class="border border-slate-600 w-9/12"></th>
                  <th class="border border-slate-600 w-1/12 text-center">Email</th>
                  <th class="border border-slate-600 w-1/12 text-center">Web</th>
                  <th class="border border-slate-600 w-1/12 text-center">App</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="notification in notifications" :key="notification.id">
                  <td class="border border-slate-600">{{ notification.label }}</td>
                  <td class="border border-slate-600 text-center">
                    <el-checkbox v-model="notification.email.value" :disabled="notification.email.disabled">
                    </el-checkbox>
                  </td>
                  <td class="border border-slate-600 text-center">
                    <el-checkbox v-model="notification.web.value" :disabled="notification.web.disabled">
                    </el-checkbox>
                  </td>
                  <td class="border border-slate-600 text-center">
                    <el-checkbox v-model="notification.app.value" :disabled="notification.app.disabled">
                    </el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </content-card>
        </div>
      </div>
    </div>
  </administration-layout>
</template>
<script>
import BusinessService from '@/services/BusinessService';
import { NotificationConfig } from '@/constants';

export default {
  name: 'Notifications',
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      loading: false,
      notifications: NotificationConfig.map(({ id, label, defaultValue, disabled }) => {
        const { email, web, app } = defaultValue;
        const { email: emailDisabled, web: webDisabled, app: appDisabled } = disabled;


        let notification = {
          id,
          label,
          email: { value: email, disabled: emailDisabled },
          web: { value: web, disabled: webDisabled },
          app: { value: app, disabled: appDisabled },
        };

        const _notification = this.$store.state.business.notifications
          .find((n) => n.businessNotificationType == id);

        if (_notification) {
          const { isEmailEnabled, isWebEnabled, isAppEnabled } = _notification;

          notification = {
            ...notification,
            email: { ...notification.email, value: isEmailEnabled ?? email },
            web: { ...notification.web, value: isWebEnabled ?? web },
            app: { ...notification.app, value: isAppEnabled ?? app },
          }
        }

        return notification;
      }),
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true;

        const data = {
          businessId: this.$store.state.account.businessId,
          businessNotifications: this.notifications.map((notification) => ({
            BusinessNotificationType: notification.id,
            IsEmailEnabled: notification.email.value,
            IsWebEnabled: notification.web.value,
            IsAppEnabled: notification.app.value,
          })),
        };

        await BusinessService.saveNotifications(data);
        this.$toastr.s("Se guardo con exito");
      } catch (error) {
        this.$toastr.e("Algo salio mal, intentalo mas tarde");
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>